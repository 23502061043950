import { ChartType, KeyType } from '../../models';

export const keys = (() => {
  let keys: StatisticsKeys = [
    {
      name: 'carrier-id',
      key: ['carrier', 'id'],
      // name: 'plan-carrierName',
      // key: ['plan', 'carrierName'],
      type: KeyType.PRICE,
      title: 'Paid Commissions by Carrier Breakdown',
      autocomplete: true,
      defaultChartType: ChartType.PIE_ADVANCED,
      class: 'md:col-span-12',
      // view: [1200, 800],
      bigHeight: true,
      bigLegend: true,
      carrierColors: true,
      diagramArea: 'TOP',
    },
    {
      name: 'agency-id',
      key: ['agency', 'id'],
      additionalKeys: ['agency.name', 'agency.profileImage'],
      type: KeyType.PRICE,
      title: 'Agency',
      autocomplete: true,
      defaultChartType: ChartType.PIE_ADVANCED,
      class: 'md:col-span-12',
      // view: [1200, 400],
      height: 'medium',
      bigLegend: true,
      diagramArea: 'TOP',
      hideFilter: true,
    },
    {
      name: 'agent-id',
      key: ['agent', 'id'],
      additionalKeys: [
        'agent.firstName',
        'agent.lastName',
        'agent.profileImage',
      ],
      type: KeyType.PRICE,
      title: 'Agent',
      autocomplete: true,
      defaultChartType: ChartType.PIE_ADVANCED,
      class: 'md:col-span-12',
      // view: [1200, 400],
      bigLegend: true,
      hideDiagram: true,
      diagramArea: 'TOP',
      hideFilter: true,
    },
  ];

  // if (!environment.production) {
  //   keys.push({
  //     name: 'marketing-vendor',
  //     key: ['marketing', 'vendor'],
  //     type: KeyType.PRICE,
  //     title: 'Marketing Vendors',
  //     autocomplete: true,
  //     defaultChartType: ChartType.PIE_ADVANCED,
  //     class: 'md:col-span-12',
  //     // view: [1200, 800],
  //     bigHeight: true,
  //     bigLegend: true,
  //     hideDiagram: true,
  //     diagramArea: 'TOP',
  //     hideFilter: true,
  //     isAdvanced: true,
  //   });
  // }
  keys = keys.concat([
    // {
    //   name: 'plan-type',
    //   key: ['plan', 'type'],
    //   type: KeyType.PRICE,
    //   title: 'Commissions Paid by Plan Type',
    //   defaultChartType: ChartType.PIE_ADVANCED,
    //   class: 'md:col-span-6',
    //   isAdvanced: true,
    //   hideDiagram: true,
    // },
    // {
    //   name: 'plan-snp',
    //   key: ['plan', 'snp'],
    //   type: KeyType.PRICE,
    //   title: 'Plan SNP',
    //   defaultChartType: ChartType.PIE_ADVANCED,
    //   class: 'md:col-span-4',
    //   isAdvanced: true,
    // },
    // {
    //   name: 'plan-planPolicyType',
    //   key: ['plan', 'planPolicyType'],
    //   type: KeyType.STRING,
    //   title: 'Plan Policy Type',
    //   defaultChartType: ChartType.PIE_ADVANCED,
    //   class: 'md:col-span-4',
    //   isAdvanced: true,
    // },
    {
      name: 'statementDate-month',
      key: ['statementDate', 'month'],
      type: KeyType.PRICE,
      title: 'Commissions Paid per Effective Date Month',
      defaultChartType: ChartType.LINE,
      class: 'md:col-span-6',
      // class: 'md:col-span-4',
      autocomplete: true,
      hideDiagram: true,
      sortBy: 'key',
    },
    // {
    //   name: 'channel-groupName',
    //   key: ['channel', 'groupName'],
    //   type: KeyType.STRING,
    //   title: 'Commissions by File',
    //   defaultChartType: ChartType.BAR_VERTICAL,
    //   autocomplete: true,
    //   class: 'md:col-span-6',
    //   scrollable: true,
    // },
    {
      name: 'channel-name',
      key: ['channel', 'name'],
      type: KeyType.STRING,
      title: 'Commissions by Statement',
      defaultChartType: ChartType.BAR_VERTICAL,
      autocomplete: true,
      class: 'md:col-span-6',
      scrollable: true,
    },
    {
      name: 'cycle-name',
      key: ['cycle', 'name'],
      type: KeyType.STRING,
      title: 'Statement Date',
      defaultChartType: ChartType.BAR_VERTICAL,
      autocomplete: true,
      class: 'md:col-span-6',
      scrollable: true,
      hideDiagram: true,
      hideFilter: true,
    },
    {
      name: 'state',
      key: ['state'],
      type: KeyType.STRING,
      title: 'Commissions Paid by State',
      // hideDiagram: true,
      defaultChartType: ChartType.BAR_VERTICAL,
      autocomplete: true,
      class: 'md:col-span-6',
      scrollable: true,
      hideDiagram: true,
    },
    {
      name: 'payout-personal',
      key: ['payout', 'personal'],
      type: KeyType.STRING,
      title: 'Commissions Count Paid by Amount',
      defaultChartType: ChartType.LINE,
      class: 'md:col-span-6',
      // class: 'md:col-span-4',
      autocomplete: true,
      sortBy: 'key',
      hideFilter: true,
    },
    {
      name: 'rate',
      key: ['identifiedResult', 'rate'],
      type: KeyType.STRING,
      title: 'Rate',
      defaultChartType: ChartType.PIE,
      class: 'md:col-span-6',
      autocomplete: true,
    },
    {
      name: 'level',
      key: ['identifiedResult', 'level'],
      type: KeyType.STRING,
      title: 'Level',
      defaultChartType: ChartType.PIE,
      class: 'md:col-span-6',
      autocomplete: true,
    },
    {
      name: 'planType',
      key: ['identifiedResult', 'planType'],
      type: KeyType.STRING,
      title: 'Plan Type',
      defaultChartType: ChartType.PIE,
      class: 'md:col-span-6',
      autocomplete: true,
    },
    {
      name: 'type',
      key: ['type'],
      type: KeyType.STRING,
      title: 'Commissions Type',
      defaultChartType: ChartType.PIE,
      class: 'md:col-span-6',
      autocomplete: true,
    },
    {
      name: 'origin',
      key: ['origin'],
      type: KeyType.STRING,
      title: 'Commissions Origin',
      defaultChartType: ChartType.PIE,
      class: 'md:col-span-6',
      autocomplete: true,
      hideDiagram: true,
    },
    {
      name: 'tags-currentTags',
      key: ['tags', 'currentTags'],
      type: KeyType.STRING,
      title: 'Current Tags',
      hideDiagram: true,
      defaultChartType: ChartType.BAR_VERTICAL,
      autocomplete: true,
      scrollable: true,
    },
  ]);

  return keys;
})();

export type StatisticsKeys = StatisticsKey[];
export interface StatisticsKey {
  name: string;
  key: string | string[];
  additionalKeys?: string[];
  type: KeyType;
  title: string;
  diagramArea?: 'TOP' | 'ADVANCED';
  hideDiagram?: boolean;
  hideFilter?: boolean;
  hideFilterReport?: boolean;
  scrollable?: boolean;
  autocomplete?: boolean;
  class?: string;
  iconClass?: string;
  sortBy?: string;
  defaultChartType?: ChartType;
  view?: [number, number];
  bigHeight?: boolean;
  bigLegend?: boolean;
  carrierColors?: boolean;
  lookupMap?: { [key: string]: any };
  isAdvanced?: boolean;
  height?: 'medium' | 'large';
}

export const heatmap_gradient = [
  'rgba(0, 255, 255, 0)',
  // "rgba(0, 255, 255, 1)",
  // "rgba(0, 191, 255, 1)",
  // "rgba(0, 127, 255, 1)",
  // "rgba(0, 63, 255, 1)",
  // "rgba(0, 0, 255, 1)",
  // "rgba(0, 0, 223, 1)",
  // "rgba(0, 0, 191, 1)",
  // "rgba(0, 0, 159, 1)",
  'rgba(170, 192, 230, 1)',
  'rgba(127, 154, 217, 1)',
  'rgba(91, 113, 204, 1)',
  'rgba(60, 81, 199, 1)',
  'rgba(34, 42, 191, 1)',
];
