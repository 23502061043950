import { DatePipe, TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBan, faUser } from '@fortawesome/pro-regular-svg-icons';
import { UserCacheService } from 'src/app/shared/services/user-cache.service';

@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [DatePipe, FaIconComponent, TitleCasePipe],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss',
})
export class UserCardComponent implements OnInit {
  @Input() type: 'card' | 'text' = 'card';
  @Input() userId?: string;

  now = new Date().getTime();

  faBan = faBan;
  faUser = faUser;

  constructor(
    private cdr: ChangeDetectorRef,
    public userCacheService: UserCacheService
  ) {}

  ngOnInit() {
    if (this.userId && !this.userCacheService.cache[this.userId]) {
      this.userCacheService
        .addToCache(this.userId)
        .then(() => this.cdr.detectChanges());
    }
  }
}
