@if (userId) {
  @switch (type) {
    @case ('text') {
      @if (userCacheService.cache[userId]; as user) {
        @if (user.firstName) {
          {{ user.firstName | titlecase }}
          {{ user.lastName | titlecase }}
        } @else {
          {{ user.name }}
        }
      } @else {
        <div class="skeleton h-4 w-32"></div>
      }
    }

    @case ('card') {
      @if (userCacheService.cache[userId]; as user) {
        <div class="flex items-center space-x-3">
          @if (user.profileImage) {
            <div class="avatar">
              <div class="mask mask-circle w-8 h-8">
                <img [src]="user.profileImage" alt="" />
              </div>
            </div>
          } @else {
            <div class="flex justify-center items-center w-8 h-8">
              <fa-icon [icon]="faUser"></fa-icon>
            </div>
          }
          <div>
            <div class="font-bold">
              @if (user.firstName) {
                {{ user.firstName | titlecase }}
                {{ user.lastName | titlecase }}
              } @else {
                {{ user.name }}
              }
              <!-- @if (a.userType === 'SELF') {
            <span class="badge gap-2 bg-secondary">
              <fa-icon [icon]="faPenLine"></fa-icon>
              Direct
            </span>
          } @else {
            <span
              *ngIf="userTypeMap[userTypeFromJSON(a.userType)]; let type"
              class="badge gap-2"
              [class.bg-secondary]="type.color === 'secondary'"
              [class.bg-accent]="type.color === 'accent'"
              [class.bg-primary]="type.color === 'primary'"
              [class.bg-green-700]="type.color === 'green'"
              [class.bg-purple-700]="type.color === 'purple'"
              [class.bg-gray-700]="type.color === 'gray'"
              [class.text-white]="
                type.color === 'primary' || type.color === 'purple'
              ">
              <fa-icon *ngIf="type.icon" [icon]="type.icon"></fa-icon>
              {{ type.title }}
            </span>
          } -->
            </div>
            @if (
              user.disabled &&
              user.disabled.startDate &&
              user.disabled.startDate.timestamp !== null &&
              user.disabled.startDate.timestamp !== undefined
            ) {
              @if (user.disabled.startDate.timestamp < now) {
                <a class="tooltip text-gray-500" data-tip="User is disabled">
                  <fa-icon [icon]="faBan"></fa-icon>
                </a>
              } @else {
                <a
                  class="tooltip text-gray-400"
                  [attr.data-tip]="
                    'User will be disabled on ' +
                    (user.disabled.startDate.timestamp | date: 'medium')
                  ">
                  <fa-icon [icon]="faBan"></fa-icon>
                </a>
              }
            }
          </div>
        </div>
      } @else {
        <!-- {{ userId }} -->
        <div class="flex items-center space-x-3">
          <div class="avatar">
            <div class="mask mask-squircle w-12 h-12">
              <div class="skeleton w-12 h-12 rounded-none"></div>
            </div>
          </div>
          <div>
            <div class="skeleton h-4 w-32"></div>
          </div>
        </div>
      }
    }
  }
}
